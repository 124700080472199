import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  InputLeftAddon,
  FormErrorMessage,
  Button,
  Select,
  Checkbox,
  Stack,
  Text,
} from "@chakra-ui/react";

const RegistrationForm = ({
  formData,
  handleInputChange,
  handleDobChange,
  handleClick,
  show,
  handleSubmit,
  validationErrors,
  agreeToTerms,
  handleTermsChange,
  loading,
}) => {
  // Email validation state
  const [emailError, setEmailError] = useState("");
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleChannelChange = (e) => {
    const { value } = e.target;
    handleInputChange(e);
    setIsOtherSelected(value === "Other");
  };

  // Update the handleInputChange to validate email
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    handleInputChange(e); // Call the parent function to update form data

    if (name === "email") {
      if (!validateEmail(value)) {
        setEmailError("Invalid email format");
      } else {
        setEmailError("");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack color="#00000080" spacing={4} marginTop="20px">
        <FormControl
          fontSize={{ base: "16px", md: "20px" }}
          fontFamily="body"
          isRequired
          isInvalid={validationErrors.firstName}
        >
          <FormLabel>First name</FormLabel>
          <Input
            name="firstName"
            placeholder="First name"
            onChange={handleInputChange}
          />
          {validationErrors.firstName && (
            <FormErrorMessage>
              <Text as="i">{validationErrors.firstName}</Text>
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          fontSize={{ base: "16px", md: "20px" }}
          fontFamily="body"
          isRequired
          isInvalid={validationErrors.lastName}
        >
          <FormLabel>Last name</FormLabel>
          <Input
            name="lastName"
            placeholder="Last name"
            onChange={handleInputChange}
          />
          {validationErrors.lastName && (
            <FormErrorMessage>
              <Text as="i">{validationErrors.lastName}</Text>
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          fontSize={{ base: "16px", md: "20px" }}
          fontFamily="body"
          isRequired
          isInvalid={validationErrors.email || emailError}
        >
          <FormLabel>Email address</FormLabel>
          <Input
            name="email"
            type="email"
            placeholder="Email"
            onChange={handleEmailChange}
          />
          {emailError && (
            <FormErrorMessage>
              <Text as="i">{emailError}</Text>
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          fontSize={{ base: "16px", md: "20px" }}
          fontFamily="body"
          isRequired
          isInvalid={validationErrors.phoneNumber}
        >
          <FormLabel>Phone number</FormLabel>
          <InputGroup>
            <InputLeftAddon children="+234" />
            <Input
              name="phoneNumber"
              type="tel"
              placeholder="Enter a valid Nigerian phone number"
              onChange={handleInputChange}
            />
          </InputGroup>
          {validationErrors.phoneNumber && (
            <FormErrorMessage>
              <Text as="i">{validationErrors.phoneNumber}</Text>
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          fontSize={{ base: "16px", md: "20px" }}
          fontFamily="body"
          isRequired
          isInvalid={validationErrors.gender}
        >
          <FormLabel>Gender</FormLabel>
          <Select
            name="gender"
            placeholder="Select your gender"
            onChange={handleInputChange}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Select>
          {validationErrors.gender && (
            <FormErrorMessage>
              <Text as="i">{validationErrors.gender}</Text>
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          fontSize={{ base: "16px", md: "20px" }}
          fontFamily="body"
          isRequired
          isInvalid={validationErrors.dob}
        >
          <FormLabel>Date of birth</FormLabel>
          <Input
            type="date"
            value={formData.dob}
            onChange={handleDobChange}
            max={new Date().toISOString().split("T")[0]}
            name="dob"
          />
          {validationErrors.dob && (
            <FormErrorMessage>
              <Text as="i">{validationErrors.dob}</Text>
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          fontSize={{ base: "16px", md: "20px" }}
          fontFamily="body"
          isRequired
          isInvalid={validationErrors.password}
        >
          <FormLabel mt="4">Password</FormLabel>
          <InputGroup size="md">
            <Input
              name="password"
              pr="4.5rem"
              type={show ? "text" : "password"}
              placeholder="Enter password"
              onChange={handleInputChange}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          {validationErrors.password && (
            <FormErrorMessage>
              <Text as="i">{validationErrors.password}</Text>
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          fontSize={{ base: "16px", md: "20px" }}
          fontFamily="body"
          isRequired
          isInvalid={validationErrors.confirmPassword}
        >
          <FormLabel mt="4">Confirm password</FormLabel>
          <InputGroup size="md">
            <Input
              name="confirmPassword"
              pr="4.5rem"
              type={show ? "text" : "password"}
              placeholder="Confirm password"
              onChange={handleInputChange}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? "Hide" : "Show"}
              </Button>
            </InputRightElement>
          </InputGroup>
          {validationErrors.confirmPassword && (
            <FormErrorMessage>
              <Text as="i">{validationErrors.confirmPassword}</Text>
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          fontSize={{ base: "16px", md: "20px" }}
          fontFamily="body"
          isRequired
          isInvalid={validationErrors.channel}
        >
          <FormLabel>How did you find us?</FormLabel>
          <Select
            name="channel"
            placeholder="Select an option"
            onChange={handleChannelChange}
          >
            <option value="Friend or Family">Friend or Family</option>
            <option value="Google">Google</option>
            <option value="Instagram">Instagram</option>
            <option value="Facebook">Facebook</option>
            <option value="Twitter">Twitter</option>
            <option value="Tiktok">Tiktok</option>
            <option value="Other">Other</option>
          </Select>
          {validationErrors.channel && (
            <FormErrorMessage>
              <Text as="i">{validationErrors.channel}</Text>
            </FormErrorMessage>
          )}
        </FormControl>

        {/* Conditionally render the "Specify" input field */}
        {isOtherSelected && (
          <FormControl
            fontSize={{ base: "16px", md: "20px" }}
            fontFamily="body"
            isRequired
            isInvalid={validationErrors.channel}
          >
            <FormLabel>Please Specify</FormLabel>
            <Input
              name="channel"
              placeholder="Please specify"
              onChange={handleInputChange}
            />
            {validationErrors.channel && (
              <FormErrorMessage>
                <Text as="i">{validationErrors.channel}</Text>
              </FormErrorMessage>
            )}
          </FormControl>
        )}
        <Checkbox
          fontFamily="body"
          isChecked={agreeToTerms}
          onChange={handleTermsChange}
          mt="4"
          style={{
            fontSize: "6px",
            fontStyle: "italic",
            cursor: "pointer",
          }}
        >
          Click to view and accept{" "}
          <Text as="span" color="#A210C6">
            Mikul Health's Terms and conditions
          </Text>
        </Checkbox>

        <Button
          mt="6"
          type="submit"
          w="full"
          bg="linear-gradient(80deg, #A210C6, #E552FF)"
          color="white"
          isLoading={loading}
          fontFamily="body"
          loadingText="Registering..."
        >
          Submit
        </Button>
      </Stack>
    </form>
  );
};

export default RegistrationForm;
